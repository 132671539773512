<template>
  <div>
    <h4 class="page-header"> RM Breakdown</h4>
    <div class="panel panel-inverse">
      <div class="panel-body">
        <div class="row">
          <div class="form-group col-md-3">
            <label>Start Date</label>
            <date-picker
                v-model="start"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
          <div class="form-group col-md-3">
            <label>End Date</label>
            <date-picker
                v-model="end"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
          <div class="form-group col-md-3">
            <label>Branch</label>
            <v-select
                v-model="branch_id"
                v-validate="'required'"
                :options="branches"
                :reduce="c => c.id"
                label="name"
                name="branch"
                placeholder="Filter By Branch"
            />
          </div>
        </div>
        <vue-good-table
            :columns="columns"
            :lineNumbers="true"
            :pagination-options="{ enabled: true,  position: 'bottom' }"
            :rows="bids"
            :search-options="{ enabled: true }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'secret'">
              <router-link :to="{ name: 'bidbonds.show', params: { id: props.row.secret }}">{{
                  props.row.reference
                }}</router-link>
            </span>
          </template>
          <div slot="table-actions">
            <download-excel
                v-if="bids.length"
                :data="bids"
                :export-fields="{
                  'Ref':'reference',
                  'Company':'company',
                  'Counterparty':'counter_party',
                  'Commission(KES)':'amount',
                  'Comment':'payment_status',
                  'Deal Date':'deal_date',
                  'RM Firstname':'firstname',
                  'RM Lastname':'lastname',
                  'Branch':'branch_name',
}"
                :title="'RM Breakdown'"
                class="btn btn-primary"
                name="RM Breakdown.xls"
            >
              <i class="fa fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import helper from "@/mixins/helper";

export default {
  mixins: [helper],
  components: {
    DatePicker
  },
  data() {
    return {
      columns: [
        {
          label: 'Ref',
          field: 'secret'
        },
        {
          label: 'Company',
          field: 'company'
        },
        {
          label: 'Counterparty',
          field: 'counter_party'
        },
        {
          label: 'Payment (KES)',
          field: 'amount'
        },
        {
          label: 'Commission (KES)',
          field: 'amount'
        },
        {
          label: 'Comment',
          field: 'payment_status'
        },
        {
          label: 'Deal Date',
          field: 'deal_date'
        },
        {
          label: 'Relationship Manager',
          field: this.relationshipmanager
        },
        {
          label: 'RM Code',
          field: 'code'
        },
        {
          label: 'Branch',
          field: 'branch_name'
        },
      ],
      branch_id: null,
      start: moment().add('-1', 'day').toDate(),
      end: moment().toDate()
    }
  },
  computed: {
    ...mapGetters({
      bids: 'getRMBids',
      branches: 'getBranches'
    }),
    period() {
      return [this.start, this.end].join();
    }
  },
  watch: {
    branch_id() {
      this.fetch();
    },
    period() {
      if (this.start && this.end) {
        this.fetch();
      }
    },
  },
  mounted() {
    this.fetch();
    if (!this.branches.length) {
      this.$store.dispatch("fetchBranches");
    }
  },
  methods: {
    relationshipmanager(rowObj) {
      return this.fullname(rowObj);
    },
    fetch() {
      this.$store.dispatch('fetchRMBids', {start: this.start, end: this.end, branch_id: this.branch_id});
    },
    getBidBonds() {
      if (!this.bids && !this.bids.results.length) {
        this.fetch();
      }
    },
  }
}

// #TODO: add url links to bidbonds report and export

</script>