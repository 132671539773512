var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"page-header"},[_vm._v(" RM Breakdown")]),_c('div',{staticClass:"panel panel-inverse"},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Start Date")]),_c('date-picker',{attrs:{"format":"yyyy-MM-dd","input-class":"form-control bg-white"},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("End Date")]),_c('date-picker',{attrs:{"format":"yyyy-MM-dd","input-class":"form-control bg-white"},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Branch")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"options":_vm.branches,"reduce":function (c) { return c.id; },"label":"name","name":"branch","placeholder":"Filter By Branch"},model:{value:(_vm.branch_id),callback:function ($$v) {_vm.branch_id=$$v},expression:"branch_id"}})],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"lineNumbers":true,"pagination-options":{ enabled: true,  position: 'bottom' },"rows":_vm.bids,"search-options":{ enabled: true }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'secret')?_c('span',[_c('router-link',{attrs:{"to":{ name: 'bidbonds.show', params: { id: props.row.secret }}}},[_vm._v(_vm._s(props.row.reference))])],1):_vm._e()]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[(_vm.bids.length)?_c('download-excel',{staticClass:"btn btn-primary",attrs:{"data":_vm.bids,"export-fields":{
                  'Ref':'reference',
                  'Company':'company',
                  'Counterparty':'counter_party',
                  'Commission(KES)':'amount',
                  'Comment':'payment_status',
                  'Deal Date':'deal_date',
                  'RM Firstname':'firstname',
                  'RM Lastname':'lastname',
                  'Branch':'branch_name',
},"title":'RM Breakdown',"name":"RM Breakdown.xls"}},[_c('i',{staticClass:"fa fa-file-excel"}),_vm._v(" Export Excel ")]):_vm._e()],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }